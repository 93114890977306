.ErrorBoundary-module__errorBoundary___RhJ6q {
  text-align: center;
  margin-top: 100px;
}
.ErrorBoundary-module__errorBoundaryImage___2Y7xS {
  width: 400px;
}
.ErrorBoundary-module__errorBoundaryTitle___3b1z6 {
  margin-top: 80px;
}
.ErrorBoundary-module__errorBoundaryText___3ZT8O {
  margin-top: 10px;
  padding: 0px 10px;
}
.ErrorBoundary-module__errorBoundaryButtons___86mjV {
  display: inline-block;
}
.ErrorBoundary-module__errorBoundaryButton___2tvBr {
  margin-top: 25px;
  width: 95px;
  margin-right: 10px;
}
.ErrorBoundary-module__errorBoundaryButtonColor___eRmBI {
  color: white;
}
@media only screen and (min-width: 600px) {
  .ErrorBoundary-module__errorBoundaryImage___2Y7xS {
    width: 600px;
  }
}
